<div class="side-menu-tab-container">
    <a
        [class.active-link]="menuTab.isActive"
        class="justify-start"
        [class.disable-link]="disableLink"
        mat-flat-button
        disableRipple
        (click)="handleOnClick()"
    >
        <mat-icon *ngIf="menuTab.icon" [svgIcon]="menuTab.isSvgIcon ? menuTab.icon : ''">
            {{ !menuTab.isSvgIcon ? menuTab.icon : '' }}
        </mat-icon>
        <div class="label">
            {{ menuTab.title }}
        </div>
    </a>
</div>
