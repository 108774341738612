import { createAction, props } from '@ngrx/store';
import { Equipment } from '../shared/interfaces/equipment.interface';
import { QuoteSummary } from 'src/app/shared/interfaces/quote-summary.interface';
import { Center } from '../shared/interfaces/center.interface';

// NOTIFICATIONS
export const notificationInfo = createAction(
    '[Root] Show info notification',
    props<{ message: string }>()
);

export const notificationSuccess = createAction(
    '[Root] Show success notification',
    props<{ message: string }>()
);

export const notificationWarning = createAction(
    '[Root] Show warning notification',
    props<{ message: string }>()
);

export const notificationError = createAction(
    '[Root] Show error notification',
    props<{ message: string }>()
);

// TOOLBAR TEXT
export const setToolbarText = createAction(
    '[Root] Set toolbar text',
    props<{ text: string }>()
);

// EQUIPMENTS
// export const getAllEquipments = createAction('[Root] Get all equipments');

export const getAllEquipmentsSuccess = createAction(
    '[Root] Get all equipments success',
    props<{ equipments: Equipment[] }>()
);

export const getAllEquipmentsFailure = createAction(
    '[Root] Get all equipments failure',
    props<{ message: string }>()
);

export const filterEquipmentById = createAction(
    '[Root] Filter equipment by id',
    props<{ query?: string }>()
);

// QUOTES

export const getQuoteList = createAction('[Quote] Get quote list');

export const getQuoteListSuccess = createAction(
    '[Quote] Get quote list success',
    props<{ quoteList: QuoteSummary[] }>()
);

export const getQuoteListFailure = createAction(
    '[Quote] Get quote list failure',
    props<{ message: string }>()
);

// AUTH

export const loginKeycloakRequest = createAction(
    '[Authentication] Login Keycloak request'
);

export const loginSuccess = createAction('[Authentication] Login success');
export const loginFailure = createAction(
    '[Authentication] Login failure',
    props<{ message: string }>()
);

export const logout = createAction('[Authentication] Logout');
export const logoutSuccess = createAction('[Authentication] Logout success');
export const logoutFailure = createAction(
    '[Authentication] Logout failure',
    props<{ message: string }>()
);
export const getUserInfo = createAction('[Authentication] Get user info');
export const getUserInfoSuccess = createAction(
    '[Authentication] Get user info success',
    props<{ userName: string }>()
);
export const getUserInfoFailure = createAction(
    '[Authentication] Get user info failure',
    props<{ message: string }>()
);

// CENTERs

export const getCenters = createAction('[Center] Get centers');
export const getCentersSuccess = createAction(
    '[Center] Get centers success',
    props<{ centers: Center[] }>()
);
export const getCentersFailure = createAction(
    '[Center] Get centers failure',
    props<{ message: string }>()
);

export const changeCenter = createAction(
    '[Center] Select center',
    props<{ center: Center }>()
);

export const changeCenterSuccess = createAction(
    '[Center] Select center success',
    props<{ center: Center }>()
);

export const setCenterFromLocalStorage = createAction(
    '[Center] Set center from local storage'
);

export const setCenterFromLocalStorageSucess = createAction(
    '[Center] Set center from local storage sucess',
    props<{ center: Center | null }>()
);

//Cart

export const getCartCount = createAction('[Equipments] Get cart count');

export const getCartCountSuccess = createAction(
    '[Equipments] Get cart count success',
    props<{ count: number }>()
);

export const getCartCountFailure = createAction(
    '[Equipments] Get cart count failure',
    props<{ message: string }>()
);

export const addPartToCart = createAction(
    '[Equipments] Add part to cart',
    props<{ equipmentId: string; partId: string; quantity: number }>()
);

export const addPartToCartSuccess = createAction(
    '[Equipments] Add part to cart success',
    props<{ count: number }>()
);

export const substractPartToCartSuccess = createAction(
    '[Equipments] Substract part to cart success',
    props<{ count: number }>()
);

export const addPartToCartFailure = createAction(
    '[Equipments] Add part to cart failure',
    props<{ message: string }>()
);
