import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { setCenterFromLocalStorage } from './store/root.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'machinex-frontend';

    constructor(private store: Store) {
        this.store.dispatch(setCenterFromLocalStorage());
    }
}
