<div class="user-tab-container">
    <ng-container *ngIf="showOptions">
        <div class="opt-container option" (click)="logout()">
            <mat-icon svgIcon="logout"></mat-icon>
            Log Out
        </div>
        <div class="divider"></div>
    </ng-container>

    <div class="opt-container" [class.active]="showOptions" (click)="handleClick()">
        <mat-icon svgIcon="account_circle"></mat-icon>
        {{ userName$ | async }}
    </div>
</div>
