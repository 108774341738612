import { getRouterSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { rootFeature } from './root.reducers';
import { Route } from '../core/enums/routes.enum';


const {
    selectEquipments,
    selectToolbarText,
    selectLoggedIn,
    selectUserName,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount
} = rootFeature;
const { selectUrl, selectQueryParams } = getRouterSelectors();
const selectedEntity = createSelector(selectQueryParams, (params) => {
    return params ? params['selected'] : undefined;
});

const selectPartId = createSelector(selectQueryParams, (params): string | undefined => {
    return params ? params['part'] : undefined;
});


const selectCurrentFeature = createSelector(selectUrl, (url) => {
    if (!url) return Route.home;
    if (url.includes('app/equipments')) return Route.equipments;
    if (url.includes('app/quotes')) return Route.quotes;
    if (url.includes('app/cart')) return Route.cart;

    return Route.home;
});


export {
    selectUrl,
    selectToolbarText,
    selectedEntity,
    selectEquipments,
    selectLoggedIn,
    selectUserName,
    selectPartId,
    selectQuoteList,
    selectCenters,
    selectCurrentCenter,
    selectCartCount,
    selectCurrentFeature
};
