import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { MenuTab } from '../../../interfaces/menu-tab';
import { Store } from '@ngrx/store';
import {
    selectCartCount,
    selectCenters,
    selectCurrentCenter,
} from 'src/app/store/root.selectors';
import * as RootActions from 'src/app/store/root.actions';
import { Center } from 'src/app/shared/interfaces/center.interface';
import { Route } from 'src/app/core/enums/routes.enum';

@Component({
    selector: 'app-side-menu-content',
    templateUrl: './side-menu-content.component.html',
    styleUrls: ['./side-menu-content.component.scss']
})
export class SideMenuContentComponent implements OnInit, OnDestroy {
    @Input() current: Route | null = null;
    @Input() collapsedMode = false;
    @Output() navigate = new EventEmitter();
    onDestroy$ = new Subject<boolean>();


    routes: { [key: string]: MenuTab };
    routesIds: string[] = [];
    selectCenters$: Observable<Center[] | null>;
    haveCenters: boolean = true;

    constructor(
        private router: Router,
        private store: Store
    ) {
        this.selectCenters$ = this.store.select(selectCenters);

        this.routes = {
            [Route.home]: {
                title: 'Home',
                id: Route.home,
                routerLink: 'home',
                icon: 'home',
                isSvgIcon: true,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/home');
                }
            },
            [Route.equipments]: {
                title: 'Equipments',
                id: Route.equipments,
                routerLink: 'equipments',
                icon: 'construction',
                isSvgIcon: true,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/equipments');
                }
            },
            [Route.cart]: {
                title: 'Cart',
                id: Route.cart,
                routerLink: 'cart',
                icon: 'shopping_cart',
                isSvgIcon: false,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/cart');
                }
            },
            [Route.quotes]: {
                title: 'Quotes',
                id: Route.quotes,
                routerLink: 'quotes',
                icon: 'request_quote',
                isSvgIcon: false,
                isActive: false,
                onClick: () => {
                    this.router.navigateByUrl('app/quotes');
                }
            }
        };

        this.routesIds = Object.values(this.routes).map((route) => route.id);
        this.subscribeToCartCount();
    }

    private subscribeToCartCount(): void {
        this.store.select(selectCurrentCenter)
            .pipe(takeUntil(this.onDestroy$), filter(center => !!center))
            .subscribe(() => {
                this.store.dispatch(RootActions.getCartCount());
            });

        this.store.select(selectCartCount)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((count) => {
                // index 2 is the cart tab
                const regex = /\((\d+)\)/;
                if (count) {
                    if (regex.test(this.routes[Route.cart].title))
                        this.routes[Route.cart].title = this.routes[Route.cart].title.replace(
                            regex,
                            `(${count})`
                        );
                    else this.routes[Route.cart].title = `${this.routes[Route.cart].title} (${count})`;
                }
                else {
                    this.routes[Route.cart].title = this.routes[Route.cart].title.replace(
                        regex,
                        ''
                    );
                }
            });

    }

    ngOnInit(): void {
        this.selectCenters$
            .pipe(take(2))
            .subscribe((centers) => {
                if (centers === null) {
                    this.haveCenters = true;
                }
                else {
                    this.haveCenters = centers?.length > 0;
                }
            });
    }

    searchFunction(query: string) {
        this.store.dispatch(RootActions.filterEquipmentById({ query }));
    }

    onNavigation() {
        this.navigate.emit();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    navigateToRoot() {
        this.router.navigate(['/']);
    }

    get isRoot() {
        return this.current === Route.home;
    }

    get isEquipment() {
        return this.current === Route.equipments;
    }

    get isQuotes() {
        return this.current === Route.quotes;
    }

    get isCart() {
        return this.current === Route.cart;
    }

    // YOU MUST ADD THIS MAPPING IN root.selectors.ts --> selectCurrentFeature
    get currentRoute(): MenuTab {
        switch (this.current) {
            case Route.cart:
                return this.routes['cart'];
            case Route.equipments:
                return this.routes['equipments'];
            case Route.quotes:
                return this.routes['quotes'];
            default:
                return this.routes['home'];;
        }
    }

}
