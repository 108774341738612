<ng-container *ngIf="haveCenters">
    <div style="margin-top: 16px" *ngIf="isRoot">
        <app-side-menu-tab
            *ngFor="let routeId of routesIds"
            [menuTab]="routes[routeId]"
            (click)="onNavigation()"
        ></app-side-menu-tab>
    </div>

    <div class="content" *ngIf="!isRoot">
        <div class="content-grid">
            <div class="header">
                <app-custom-button
                    [text]="collapsedMode ? undefined : 'Back to home'"
                    icon="arrow_back_ios_new"
                    type="link"
                    *ngIf="!isRoot"
                    (buttonClick)="navigateToRoot()"
                    class="back-button"
                    [class.justify-center]="false"
                ></app-custom-button>
                <app-side-menu-tab
                    [disableLink]="true"
                    [menuTab]="currentRoute"
                ></app-side-menu-tab>
                <app-search-input
                    *ngIf="isEquipment"
                    (search)="searchFunction($event)"
                ></app-search-input>
            </div>
            <div class="body">
                <app-equipments-list *ngIf="isEquipment"></app-equipments-list>
                <app-quotes-list *ngIf="isQuotes"></app-quotes-list>
            </div>
            <div class="footer">
                <app-center-manager></app-center-manager>
                <app-user-tab></app-user-tab>
            </div>
        </div>
    </div>
</ng-container>
