import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistryModule } from '../modules/mat-icon-registry/mat-icon-registry.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { LoaderComponent } from './components/loader/loader.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { ListViewItemComponent } from './components/list-view-item/list-view-item.component';
import { TextItemComponent } from './components/text-item/text-item.component';
import { CustomSliderComponent } from './components/custom-slider/custom-slider.component';
import { FormsModule } from '@angular/forms';
import { ModelControlPanelComponent } from './components/model-control-panel/model-control-panel.component';
import { HotspotComponent } from './components/hotspot/hotspot.component';
import { CustomButtonQtyComponent } from './components/custom-button-qty/custom-button-qty.component';
import { QtySelectorComponent } from './components/qty-selector/qty-selector.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { AutoScrollToSelectedDirective } from './directives/auto-scroll-to-selected.directive';
import { ScrollShadowDirective } from './directives/scroll-shadows.directive';

@NgModule({
    declarations: [
        LoaderComponent,
        CustomButtonComponent,
        ListViewComponent,
        ListViewItemComponent,
        TextItemComponent,
        CustomSliderComponent,
        ModelControlPanelComponent,
        HotspotComponent,
        CustomButtonQtyComponent,
        QtySelectorComponent,
        DeleteConfirmationComponent,
        AutoScrollToSelectedDirective,
        ScrollShadowDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconRegistryModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        RouterModule,
        MatButtonModule,
        MatMenuModule,
        MatSliderModule
    ],
    exports: [
        MatIconRegistryModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        RouterModule,
        MatButtonModule,
        LoaderComponent,
        CustomButtonComponent,
        MatMenuModule,
        MatSliderModule,
        ListViewComponent,
        ListViewItemComponent,
        TextItemComponent,
        CustomSliderComponent,
        ModelControlPanelComponent,
        HotspotComponent,
        QtySelectorComponent,
        DeleteConfirmationComponent,
        CustomButtonQtyComponent,
        AutoScrollToSelectedDirective,
        ScrollShadowDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
